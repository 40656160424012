import { useState, useMemo, Suspense } from 'react';
import { useFetch } from '../../../../Hooks/useFetch';
import Carousel from './Carousel';
import classNames from 'classnames';
import { useDeviceDetect } from '../../../../LoadModules/DeviceDetect';
import * as styleimported from '../../../Styled/CarouselNewProductsStyled';
import { ProductInterface } from '../../../../Interfaces/Product';
import { TaxonInterface } from '../../../../Interfaces/Taxon';
import { taxonService, productsService } from '../../../../Services/Services';
import Loader from '../../../../LoadModules/Loader';

export default function Carousels() {
    const { isMobile } = useDeviceDetect();

    const { data: bestSalesTaxon, isLoading: bestSalesLoading } = useFetch<TaxonInterface | TaxonInterface[], []>({
        service: () => new taxonService().getTaxon('BEST-SALES'),
        cacheKey: 'taxon_best_sales',
        dependencies: [],
    });

    const { data: newTaxon, isLoading: newLoading } = useFetch<TaxonInterface | TaxonInterface[], []>({
        service: () => new taxonService().getTaxon('NEW'),
        cacheKey: 'taxon_new',
        dependencies: [],
    });

    const [selectedTab, setSelectedTab] = useState('bestSales');

    const taxonCarousel = useMemo(() => (selectedTab === 'bestSales' ? bestSalesTaxon : newTaxon), [selectedTab, bestSalesTaxon, newTaxon]);

    const taxonCode = useMemo(() => {
        if (Array.isArray(taxonCarousel) && taxonCarousel.length > 0) {
            return [taxonCarousel[0].code];
        } else if (taxonCarousel && !Array.isArray(taxonCarousel)) {
            return [taxonCarousel.code];
        } else {
            return null;
        }
    }, [taxonCarousel]);

    const { data: productsData, isLoading: isLoadingProducts } = useFetch<{ products: ProductInterface[] }, (string[] | null)[]>({
        service: () => new productsService().getAllProducts(taxonCode ? taxonCode : null, 1, 10),
        cacheKey: `products_${taxonCode ? taxonCode.join('_') : 'all'}`,
        dependencies: [taxonCode],
    });

    const handleTabClick = (tab: string) => {
        setSelectedTab(tab);
    };

    if (bestSalesLoading || newLoading) {
        return <Loader />;
    }

    const breakpoints = {
        200: {
            slidesPerView: 2.8,
            slidesPerGroup: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 50,
        },
    };

    return (
        <Suspense fallback={<div>Loading carousels ....</div>}>
            <div className='shop-carousels'>
                {!Array.isArray(bestSalesTaxon) && bestSalesTaxon && !Array.isArray(newTaxon) && newTaxon && (
                    <div
                        key={bestSalesTaxon.id}
                        className={'shop-container'}
                    >
                        <div className={classNames({ 'is-flex': !isMobile, 'is-justify-content-center': !isMobile, 'ml-5': true, 'mr-5': true })}>
                            <styleimported.Tabs className='tabs is-centered  '>
                                <ul>
                                    <li className={selectedTab === 'bestSales' ? 'is-active' : 'is-not-active'}>
                                        <a
                                            onClick={() => handleTabClick('bestSales')}
                                            className='header-text'
                                        >
                                            {bestSalesTaxon.name}
                                        </a>
                                    </li>
                                    <li className={selectedTab === 'news' ? 'is-active' : 'is-not-active'}>
                                        <a
                                            onClick={() => handleTabClick('news')}
                                            className='header-text'
                                        >
                                            {newTaxon.name}
                                        </a>
                                    </li>
                                </ul>
                            </styleimported.Tabs>
                        </div>

                        <styleimported.SlidesStyledCarousel>
                            <Suspense fallback={<Loader />}>
                                <Carousel
                                    styleimported={styleimported}
                                    breakpoints={breakpoints}
                                    products={productsData?.products || []}
                                    isLoading={isLoadingProducts}
                                />
                            </Suspense>
                        </styleimported.SlidesStyledCarousel>
                    </div>
                )}
            </div>
        </Suspense>
    );
}
